@import "~ladda/dist/ladda-themeless.min";
@import "~toastr/build/toastr";
@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker3";
@import "~timepicker/jquery.timepicker";
@import "~fullcalendar/dist/fullcalendar";
@import "~trix/dist/trix";
@import "~filepond/dist/filepond.min";
@import '~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min';
@import '~@pqina/pintura/pintura';
@import '~@pqina/pintura-video/pinturavideo';
@import '~filepond-plugin-file-poster/dist/filepond-plugin-file-poster.min';
@import "~css-device-frames/dist/device-frames";
@import '~tributejs/dist/tribute';

// overrides for tributejs
.tribute-container {
  border-radius: 4px;
}
.tribute-container ul {
  background:  #F1F5F8; // $n0;
  border-radius: 4px;
}

.tribute-container ul li {
  border-radius: 4px;
}

.tribute-container li.highlight {
  background: #27AA83;
  color: white;
}

// Overrides for vue-datepicker
$namespace: 'wiq'; // change the 'mx' to 'xmx'. then <date-picker prefix-class="xmx" />

$default-color: #324D67; // $n7
$primary-color: #27AA83; // $p5
.wiq-btn-icon-double-left, .wiq-btn-icon-double-right {
  display: none
}
@import '~vue2-datepicker/scss/index.scss';
