.v-popper--theme-info-tooltip {
  $color: hsl(209, 28%, 39%);
  display: inline-block;
  color: hsl(205, 65%, 55%);

  .v-popper__inner {
    background: $color;
    color: white;
    padding: 24px;
    border-radius: 5px;
    box-shadow: 0 5px 30px rgba(black, .1);
    max-width: 250px;
  }

  .v-popper__arrow {
    border-color: $color;
  }

  .header {
    font-size: 14px;
    font-weight: 700;
  }

  p {
    font-size: 12px;
    font-weight: normal;
  }

  a {
    color: white;
    text-decoration: underline;
  }

  a:hover {
    color: hsl(205, 79%, 92%);
  }
}